<template>
    <div class="panel box">
    
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
    
                <div class="level-left has-text-centered" @click="showFuelPriceCalculator=!showFuelPriceCalculator">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-plus-square has-text-primary" v-if="!showFuelPriceCalculator && !isPrinting"></i>
                    <i class="fas fa-minus-square has-text-danger" v-if="showFuelPriceCalculator && !isPrinting"></i>
                </div>
                <div class="column is-size-6 is-family-sans-serif has-text-centered"> FUEL BREAK-EVEN CALCULATOR </div> 
            </div>
        </div>
        <div class="panel-block">
            <div class="control columns custom-boxes-container has-text-weight-bold" 
                 v-if="showFuelPriceCalculator">
                 <article class="column is-6">
                    <br>
                    <div class="columns has-background-white-ter  is-mobile">
                        <div class="column is-4">
                            Fuel Product: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    Regular
                                </div>
                                <div class="column has-text-right is-4">
                                    Premium
                                </div>
                                <div class="column has-text-right is-4">
                                    Diesel
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            Sales Taxable: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    <b-switch 
                                        class="is-small"
                                        v-model="settingsValues.fpcalcTaxableRegular.value"
                                        :true-value=1
                                        :false-value=0
                                        @input="reCalculate">
                                    </b-switch>
                                </div>
                                <div class="column has-text-right is-4">
                                    <b-switch 
                                        class="is-small"
                                        v-model="settingsValues.fpcalcTaxablePremium.value"
                                        :true-value=1
                                        :false-value=0
                                        @input="reCalculate">
                                    </b-switch>
                                </div>
                                <div class="column has-text-right is-4">
                                    <b-switch 
                                        class="is-small"
                                        v-model="settingsValues.fpcalcTaxableDiesel.value"
                                        :true-value=1
                                        :false-value=0
                                        @input="reCalculate">
                                    </b-switch>
                                </div>
                            </div>
                        </div>
                        
                    </div>
    
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            Purchase Price: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="4"
                                        postfix="/gal"
                                        v-model="settingsValues.fpcalcBasePriceRegular.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcBasePriceRegular.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>
                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="4"
                                        postfix="/gal"
                                        v-model="settingsValues.fpcalcBasePricePremium.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcBasePricePremium.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>
                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="4"
                                        postfix="/gal"  
                                        v-model="settingsValues.fpcalcBasePriceDiesel.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcBasePriceDiesel.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>
                            </div>
                        </div>
                        
                    </div>                    

                    <!-- <div class="columns veecli-table is-mobile">
                        <div class="column is-6">
                            Other Taxes: 
                        </div>
                        <div class="column has-text-right is-2">
                            {{ getTotalGasolineTax()| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                        </div>
                        <div class="column has-text-right is-2">
                            {{ getTotalGasolineTax() | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                        </div>
                        <div class="column has-text-right is-2">
                            {{ getTotalDieselTax() | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                        </div>
                    </div>    -->
                    
                    <div class="veecli-input-bottom-border"></div>

                    <div class="columns veecli-table has-background-white-ter is-mobile">
                        <div class="column is-4">
                            Break-even Price: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ settingsValues.fpcalcBreakEvenPriceRegular.value | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ settingsValues.fpcalcBreakEvenPricePremium.value | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ settingsValues.fpcalcBreakEvenPriceDiesel.value | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <!-- <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Sales Tax: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcBreakEvenPriceRegular.value, "Regular") | formatCurrency }}
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcBreakEvenPricePremium.value, "Premium") | formatCurrency }}
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcBreakEvenPriceDiesel.value, "Diesel") | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        
                    </div>  -->
                    
                    <div class="veecli-spacer-row"></div>

                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            Sales Price: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="3"
                                        postfix="/gal"  
                                        v-model="settingsValues.fpcalcRegularCashPrice.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcRegularCashPrice.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>

                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="3"
                                        postfix="/gal"  
                                        v-model="settingsValues.fpcalcPremiumCashPrice.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcPremiumCashPrice.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>

                                <div class="column has-text-right is-4">
                                    <VeeInput 
                                        type="currency" 
                                        decimals="3"
                                        postfix="/gal"  
                                        v-model="settingsValues.fpcalcDieselCashPrice.value"
                                        name="invoicedVolume"
                                        :value="settingsValues.fpcalcDieselCashPrice.value"
                                        :countryCurrencyCode="countryCurrencyCode"
                                        :editable="trueValue"
                                        @input="reCalculate"
                                        />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top:-1.5em;"></div>

                    <div class="columns veecli-table has-background-white-ter is-mobile ">
                        <div class="column is-4">
                            Profit: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4" :class="{'has-text-danger': getProfitAmount(settingsValues.fpcalcRegularCashPrice.value,'Regular') < 0.00 }">
                                    {{ getProfitAmount(settingsValues.fpcalcRegularCashPrice.value,"Regular") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4" :class="{'has-text-danger': getProfitAmount(settingsValues.fpcalcPremiumCashPrice.value,'Premium') < 0.00 }">
                                    {{ getProfitAmount(settingsValues.fpcalcPremiumCashPrice.value,"Premium") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4" :class="{'has-text-danger': getProfitAmount(settingsValues.fpcalcDieselCashPrice.value,'Diesel') < 0.00 }">
                                    {{ getProfitAmount(settingsValues.fpcalcDieselCashPrice.value,"Diesel") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>                        
                    </div>
                 
                    <div style="margin-top:3em;"></div>

                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Before Sales Tax: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getBeforeTax(settingsValues.fpcalcRegularCashPrice.value, "Regular")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getBeforeTax(settingsValues.fpcalcPremiumCashPrice.value, "Premium")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getBeforeTax(settingsValues.fpcalcDieselCashPrice.value, "Diesel")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Sales Tax: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcRegularCashPrice.value,"Regular") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcPremiumCashPrice.value,"Premium") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getIncludeSalesTax(settingsValues.fpcalcDieselCashPrice.value,"Diesel") | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    
                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Total Other Taxes: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getTotalGasolineTax()| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getTotalGasolineTax() | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getTotalDieselTax() | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>                        
                    </div>                     
                    
                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Freight:
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ this.settingsValues.fpcalcFreightPerGal.value| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ this.settingsValues.fpcalcFreightPerGal.value | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ this.settingsValues.fpcalcFreightPerGal.value | formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>                        
                    </div>                    

                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Credit Card Fee: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getCreditCardFee(settingsValues.fpcalcRegularCashPrice.value, "Regular")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getCreditCardFee(settingsValues.fpcalcPremiumCashPrice.value, "Premium")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getCreditCardFee(settingsValues.fpcalcDieselCashPrice.value, "Diesel")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>





                    <div class="columns veecli-table is-mobile">
                        <div class="column is-4">
                            Unpaid Sales Tax: 
                        </div>
                        <div class="column is-8">
                            <div class="columns is-mobile">
                                <div class="column has-text-right is-4">
                                    {{ getUnpaidSalesTax(settingsValues.fpcalcRegularCashPrice.value,"Regular")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getUnpaidSalesTax(settingsValues.fpcalcPremiumCashPrice.value,"Premium")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ getUnpaidSalesTax(settingsValues.fpcalcDieselCashPrice.value,"Diesel")| formatCurrency }}<span v-if="!this.isNarrowScreen()">/gal</span>
                                </div>
                            </div>
                        </div>                        
                    </div>                   

                    <footer class="modal-card-foot">
                        <button class="button is-success" type="button" @click="submitForm" :disabled='isSaveDisabled'>Save</button>
                    </footer>

                    <b-message class="is-info">
                        This calculator is for informational purposes only and should not be used to prepare official tax returns. VEECLi does not guarantee the accuracy of the calculated amounts, which should not be relied upon when determining payments owed to federal, state, or local tax authorities.
                    </b-message>
                    

                 </article>
                 <article class="column is-6 box">
                    <br>
                    <div class="columns has-background-white-ter is-mobile">
                        <div class="column is-4">
                            Description 
                        </div>
                        <div class="column has-text-right is-4">
                            Diesel
                        </div>
                        <div class="column has-text-right is-4">
                            Gasoline
                        </div>
                    </div>
                    <!-- <div class="columns is-mobile">
                        <div class="column is-8">
                            Required Profit/Gal: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="3"
                                v-model="settingsValues.fpcalcRequiredProfit.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcRequiredProfit.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div> -->
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            Credit Card Rate %: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="number" 
                                postfix="%"
                                decimals="5" 
                                v-model="settingsValues.fpcalcCreditCardPerc.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcCreditCardPerc.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            Freight: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5"
                                v-model="settingsValues.fpcalcFreightPerGal.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcFreightPerGal.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            {{ storeState.name }} Sales Tax %: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="number" 
                                postfix="%" 
                                v-model="settingsValues.fpcalcSalesTaxPerc.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcSalesTaxPerc.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            {{ storeState.name }} Prepaid Sales Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5"
                                v-model="settingsValues.fpcalcSalesTaxPrepaid.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcSalesTaxPrepaid.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>

                    <div class="veecli-spacer-row"></div>

                    <div class="columns has-background-grey-lighter ">
                        <div class="column is-mobile veecli-table-head has-text-centered">
                            Other Taxes
                        </div>
                    </div>

                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            {{ storeState.name }} Fuel Use Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5"
                                v-model="settingsValues.fpcalcStateDieselUseTax.value"
                                name="creditCardFees"
                                :value="settingsValues.fpcalcStateDieselUseTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5"
                                v-model="settingsValues.fpcalcStateGasolinelUseTax.value"
                                name="creditCardFees"
                                :value="settingsValues.fpcalcStateGasolinelUseTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            {{ storeState.name }} Motor Fuel Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcStateDieselTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcStateDieselTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcStateGasolineTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcStateGasolineTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>                    
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            {{ storeState.name }} UST Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcStateUSTTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcStateUSTTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            {{ storeState.name }} Env. Impact Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcStateENVTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcStateENVTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            Federal Excise: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcFedDieselExciseTax.value"
                                name="prepaidSalesTax"
                                :value="settingsValues.fpcalcFedDieselExciseTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcFedGasolineExciseTax.value"
                                name="prepaidSalesTax"
                                :value="settingsValues.fpcalcFedGasolineExciseTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>                    
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-4">
                            Federal HazMat: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcFedDieselHAZSSF.value"
                                name="fpcalcFedDieselHAZSSF"
                                :value="settingsValues.fpcalcFedDieselHAZSSF.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcFedGasolineHAZSSF.value"
                                name="fpcalcFedDieselHAZSSF"
                                :value="settingsValues.fpcalcFedGasolineHAZSSF.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            Federal Leaking UST Tax
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcFedLUSTTax.value"
                                name="invoiceAmount"
                                :value="settingsValues.fpcalcFedLUSTTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            Federal Oil Spill Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5"
                                v-model="settingsValues.fpcalcFedOilSpillTax.value"
                                name="salesTaxPercent"
                                :value="settingsValues.fpcalcFedOilSpillTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            City Fuel Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcCityFuelTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcCityFuelTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                        
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-8">
                            County Fuel Tax: 
                        </div>
                        <div class="column has-text-right is-4">
                            <VeeInput 
                                type="currency" 
                                postfix="/gal" 
                                decimals="5" 
                                v-model="settingsValues.fpcalcCountyFuelTax.value"
                                name="cityTaxPerGal"
                                :value="settingsValues.fpcalcCountyFuelTax.value"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                @input="reCalculate"
                                />
                        </div>
                    </div>    
                 </article>
            </div>
        </div>      
    </div>
    
    </template>
    
    <script>
    
    
    import VeeInput from './VeeInput.vue'
    import UserStore from "../../store/UserStore"
    import axios from 'axios'

    export default {
    
        data() {
            return {
                user: this.getUser(),
                isSaveDisabled: true,
                storeSettings: [],
                newSettings: [],
                existingSettings: [],
                storeSettingsOptions: UserStore.getters.getStoreSettingsOptions,
                settingsValues: UserStore.getters.getStoreSettingsValues,
                storeState: UserStore.getters.getStoreState,
                showNumberControls: true,
                showFuelPriceCalculator: true,
                isPrinting: false,
                trueValue: true,
                falseValue: false,
                screenWidth: window.screen.width,
                fpCalcDefaults: {
                    "fpcalcFedGasolineHAZSSF": 0.00364,
                    "fpcalcFedDieselHAZSSF": 0.00348,
                    "fpcalcFedLUSTTax": 0.00100,
                    "fpcalcFedOilSpillTax": 0.00193,
                    "fpcalcFedGasolineExciseTax": 0.184,
                    "fpcalcFedDieselExciseTax": 0.244,
                    "fpcalcCreditCardPerc": 2.1,
                    "fpcalcFreightPerGal": 0.05,
                    "fpcalcTaxableRegular": 1,
                    "fpcalcTaxablePremium": 1,
                    "fpcalcTaxableDiesel": 0,
                    "states": [
                        {
                            "code": "IL",
                            "fpcalcSalesTaxPerc": 7.25,
                            "fpcalcStateGasolinelUseTax": 0.19600,
                            "fpcalcStateDieselUseTax": 0.21200,
                            "fpcalcStateUSTTax": 0.00300,
                            "fpcalcStateENVTax": 0.00800,
                            "fpcalcStateGasolineTax": 0.4700,
                            "fpcalcStateDieselTax": 0.54500,
                            "fpcalcSalesTaxPrepaid": 0.19
                        },
                        {
                            "code": "IN",
                            "fpcalcSalesTaxPerc": 0,
                            "fpcalcStateGasolinelUseTax": 0.203,
                            "fpcalcStateDieselUseTax": 0.0,
                            "fpcalcStateUSTTax": 0.0,
                            "fpcalcStateENVTax": 0.0,
                            "fpcalcStateGasolineTax": 0.18,
                            "fpcalcStateDieselTax": 0.16,
                            "fpcalcSalesTaxPrepaid": 0.0
                        }                        
                    ]
                },                    
                fuelPrice: {
                    "invoiceNumber": "",
                    "invoiceAmount": 0,
                    "salesTaxPercent": 0,
                    "prepaidSalesTax": 0,
                    "countyTaxPerGal": 0,
                    "cityTaxPerGal": 0,
                    "creditCardFees": 0,
                    "profitPerGal": 0,
                    "totalFreightAndTaxes": 0,
                    "totalInvoicedVolume": 0,
                    "totalFuelBasePrice": 0,
                    "totalPrepaidTax": 0,
                    "totalCityTax": 0,
                    "totalCountyTax": 0,
                    "totalProfit": 0,
                    "totalNetProfit": 0,
                    "totalSalesTax": 0,
                    "fuelProducts": [
                        {
                            "productName": "Regular",
                            "taxable": true,
                            "invoicedVolume": 0,
                            "invoicedPrice": 0,
                            "taxableSalesPrice": 0,
                            "countyTaxPerGal": 0,
                            "cityTaxPerGal": 0,
                            "freightAndTaxes": 0,
                            "salesTax": 0,
                            "cashPrice": 0,
                            "creditPrice": 0,
                            "unpaidSalesTax": 0,
                            "totalTaxDue": 0
                        },
                        {
                            "productName": "Premium",
                            "taxable": true,
                            "invoicedVolume": 0,
                            "invoicedPrice": 0,
                            "taxableSalesPrice": 0,
                            "salesTax": 0,
                            "countyTaxPerGal": 0,
                            "cityTaxPerGal": 0,
                            "freightAndTaxes": 0,
                            "cashPrice": 0,
                            "creditPrice": 0,
                            "unpaidSalesTax": 0,
                            "totalTaxDue": 0
                        },
                        // {
                        //     "productName": "Midrange",
                        //     "taxable": true,
                        //     "invoicedVolume": 0,
                        //     "invoicedPrice": 0,
                        //     "taxableSalesPrice": 0,
                        //     "salesTax": 0,
                        //     "countyTaxPerGal": 0,
                        //     "cityTaxPerGal": 0,
                        //     "freightAndTaxes": 0,
                        //     "cashPrice": 0,
                        //     "creditPrice": 0,
                        //     "unpaidSalesTax": 0,
                        //     "totalTaxDue": 0
                        // },
                        {
                            "productName": "Diesel",
                            "taxable": false,
                            "invoicedVolume": 0,
                            "invoicedPrice": 0,
                            "taxableSalesPrice": 0,
                            "salesTax": 0,
                            "countyTaxPerGal": 0,
                            "cityTaxPerGal": 0,
                            "freightAndTaxes": 0,
                            "cashPrice": 0,
                            "creditPrice": 0,
                            "unpaidSalesTax": 0,
                            "totalTaxDue": 0
                        },
                    ]
                },
                countryCurrencyCode: this.getCountryCurrencyCode()
            }
        },
    
        components: {
            VeeInput
        },
    
        methods: {

            fetchSettings() {
                if (this.user.activeStore) {
                    const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                                + '/settings'

                    axios.get(url,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                            this.storeSettings = response.data.data

                            this.setSettingsValues()
                            this.isLoading = false
                        })
                        .catch( (err) => {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            this.isLoading = false
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        })
                }
                
            },

            setSettingsValues() {

                this.storeSettingsOptions.forEach( (item) => {
                    this.settingsValues[item.field] = this.storeSettings.find(o => o.key === item.key)

                    

                    if (this.settingsValues[item.field] === undefined) {
                        this.settingsValues[item.field] = {
                            "id": 0,
                            "key": item.key,
                            "value": ""
                        }

                        if (item.type == "number") {
                            this.settingsValues[item.field].value = 0
                        }
                        if (item.type == "string") {

                            // Initialize with Defaults for State and Federal
                            if (item.field.startsWith('fpcalc')) {
                                this.settingsValues[item.field].value = (this.fpCalcDefaults[item.field] ? this.fpCalcDefaults[item.field] : "")

                                if (this.settingsValues[item.field].value == "") {
                                    const stateDefaults = this.fpCalcDefaults.states.find(state => state.code == this.storeState.code)

                                    if (stateDefaults) {
                                        this.settingsValues[item.field].value = (stateDefaults[item.field] ? stateDefaults[item.field] : 0)
                                    } else {
                                        this.settingsValues[item.field].value = 0
                                    }
                                }
                            }
                        }
                    }

                    if (item.type == "number" && this.settingsValues[item.field] !== undefined) {
                        this.settingsValues[item.field].value = this.getNumber(this.settingsValues[item.field].value)
                    }

                    if (this.settingsValues.lotteryInsideSaleDirection.value == "") {
                        this.settingsValues.lotteryInsideSaleDirection.value = 0
                    }

                })
                this.reCalculate()


            },

            submitForm() {
                if (  UserStore.getters.getActiveStore.storeId ) {
                    // let postComplete = false
                    this.storeSettingsOptions.forEach( (item) => {

                        var setting = this.storeSettings.find(o => o.key === item.key)
                        if (setting === undefined) {
                            var newItem = {
                                "id": 0,
                                "key": item.key,
                                "value": this.settingsValues[item.field].value,
                            }
                            this.newSettings.push(newItem)                           
                        } else {
                            this.existingSettings.push({
                                "id": this.settingsValues[item.field].id,
                                "key": this.settingsValues[item.field].key,
                                "value": this.settingsValues[item.field].value,
                            })
                        }
                    })
                    
                    const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                                + '/settings'

                    if (this.newSettings.length > 0) {
                        axios.post(url, 
                            this.newSettings,
                            {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken					},				
                            })
                        .then( response => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)                            
                        })
                        .catch( (err) => {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
                        })
                    }

                    if (this.existingSettings.length > 0) {
                        axios.put(url, 
                            this.existingSettings,
                            {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken					},				
                            })
                        .then( response => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        })
                        .catch( (err) => {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
                        })
                    }

                    this.isSaveDisabled = true
                }
            },            

            reCalculate() {
                this.isSaveDisabled = false
                this.settingsValues.fpcalcBasePriceRegular.value = this.getNumber(this.settingsValues.fpcalcBasePriceRegular.value)
                this.settingsValues.fpcalcBasePricePremium.value = this.getNumber(this.settingsValues.fpcalcBasePricePremium.value)
                this.settingsValues.fpcalcBasePriceDiesel.value = this.getNumber(this.settingsValues.fpcalcBasePriceDiesel.value)

                this.settingsValues.fpcalcBreakEvenPriceRegular.value = this.getBreakEvenPrice(this.settingsValues.fpcalcBasePriceRegular.value, "Regular")
                this.settingsValues.fpcalcBreakEvenPricePremium.value = this.getBreakEvenPrice(this.settingsValues.fpcalcBasePricePremium.value, "Premium")
                this.settingsValues.fpcalcBreakEvenPriceDiesel.value = this.getBreakEvenPrice(this.settingsValues.fpcalcBasePriceDiesel.value, "Diesel")

            },

            getCommonTax() {
                return this.getNumber(this.settingsValues.fpcalcStateUSTTax.value) +
                    this.getNumber(this.settingsValues.fpcalcStateENVTax.value) +
                    this.getNumber(this.settingsValues.fpcalcFedLUSTTax.value) +
                    this.getNumber(this.settingsValues.fpcalcFedOilSpillTax.value) +
                    this.getNumber(this.settingsValues.fpcalcCityFuelTax.value) +
                    this.getNumber(this.settingsValues.fpcalcCountyFuelTax.value)
            },

            getTotalGasolineTax() {
                return this.getNumber(this.getCommonTax()) + 
                    this.getNumber(this.settingsValues.fpcalcStateGasolinelUseTax.value) +
                    this.getNumber(this.settingsValues.fpcalcStateGasolineTax.value) +
                    this.getNumber(this.settingsValues.fpcalcFedGasolineHAZSSF.value) +
                    this.getNumber(this.settingsValues.fpcalcFedGasolineExciseTax.value) 
            },

            getTotalDieselTax() {
                return this.getNumber(this.getCommonTax()) +
                    this.getNumber(this.settingsValues.fpcalcStateDieselUseTax.value) +
                    this.getNumber(this.settingsValues.fpcalcStateDieselTax.value) +
                    this.getNumber(this.settingsValues.fpcalcFedDieselHAZSSF.value) +
                    this.getNumber(this.settingsValues.fpcalcFedDieselExciseTax.value) 
            },

            getTaxable(product) {

                if (product == "Regular") {
                    return this.getNumber(this.settingsValues.fpcalcTaxableRegular.value)
                }
                
                if (product == "Premium") {
                    return this.getNumber(this.settingsValues.fpcalcTaxablePremium.value)
                }

                if (product == "Diesel") {
                    return this.getNumber(this.settingsValues.fpcalcTaxableDiesel.value)
                }                
            },

            getBreakEvenPrice(price, product) {
                if (this.getBeforeTax(price,product) === 0) return 0
                var breakEven = 0
                if (product == "Diesel") {
                    breakEven = this.getTotalDieselTax()
                } else {
                    breakEven = this.getTotalGasolineTax()
                }
                breakEven += price +
                    this.getNumber(this.settingsValues.fpcalcFreightPerGal.value)

                if (this.getTaxable(product)) {
                    breakEven += this.getSalesTax(breakEven,product)
                } 
                return this.getNumber(breakEven)
            },

            getSalesTax(price, product) {
                if (this.getTaxable(product)) {
                    return this.getNumber((price * this.getNumber((this.settingsValues.fpcalcSalesTaxPerc.value) / 100)).toFixed(2))
                } 
                return 0
            },

            getIncludeSalesTax(price,product) {
                if (this.getTaxable(product)) {
                    return price - (price / ((100 + this.getNumber(this.settingsValues.fpcalcSalesTaxPerc.value)) / 100))
                }
                return 0
            },

            getUnpaidSalesTax(price,product) {

                if (this.getTaxable(product)) {
                    return this.getIncludeSalesTax(price,product) - this.getNumber(this.settingsValues.fpcalcSalesTaxPrepaid.value)
                } else {
                    return 0
                }


            },

            getCostPlusTax(price, product) {
                return price + this.getIncludeSalesTax(price,product)
            },

            getBeforeTax(price,product) {
                // if (this.getTaxable(product)) {
                //     return price / this.getNumber(this.settingsValues.fpcalcSalesTaxPerc.value)
                // }
                return price - this.getIncludeSalesTax(price, product)
            },

            getProfitAmount(price,product) {
                var profit = 0
                
                if (product == "Regular") {

                    
                    profit = price -
                        this.getNumber(this.settingsValues.fpcalcBasePriceRegular.value) -
                        this.getTotalGasolineTax() -
                        this.getNumber(this.settingsValues.fpcalcFreightPerGal.value) -
                        this.getIncludeSalesTax(price,product)

                    // profit = this.getIncludeSalesTax(price,product)
                }
                if (product == "Premium") {
                    profit = price - 
                        this.getNumber(this.settingsValues.fpcalcBasePricePremium.value) - 
                        this.getTotalGasolineTax() -
                        this.getIncludeSalesTax(price,product) -
                        this.getNumber(this.settingsValues.fpcalcFreightPerGal.value)
                }
                if (product == "Diesel") {
                    profit = price - 
                        this.getNumber(this.settingsValues.fpcalcBasePriceDiesel.value) -
                        this.getTotalDieselTax() -
                        this.getIncludeSalesTax(price,product) -
                        this.getNumber(this.settingsValues.fpcalcFreightPerGal.value)                           
                }

                return profit
            },

            getCreditCardFee(price) {
                if (this.settingsValues.fpcalcCreditCardPerc.value === undefined) this.settingsValues.fpcalcCreditCardPerc.value = 0
                return price * this.getNumber(this.settingsValues.fpcalcCreditCardPerc.value) / 100
            },
          
        },

        mounted() {
            if (this.settingsValues.fpcalcTaxableRegular.value === undefined ) this.settingsValues.fpcalcTaxableRegular.value = true
            if (this.settingsValues.fpcalcTaxablePremium.value === undefined) this.settingsValues.fpcalcTaxablePremium.value = true
            if (this.settingsValues.fpcalcTaxableDiesel.value === undefined) this.settingsValues.fpcalcTaxableDiesel.value = true
            this.fetchSettings()
        },

    
    }
    </script>
    
    
    <style>
    .box {
        margin: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
        /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    }

    .veecli-table-head {
        vertical-align: middle;
        /* padding: 3px; */
    }
    
    /* .veecli-table { */
        /* border-bottom: 0.5px solid gray; */
        /* padding-bottom: 1.0em;
        margin-top: 0.5em;
        height: 0.5em; */
    /* } */
    
    .custom-boxes-container .box:last-child { margin-bottom: 1.5rem;}

    .veecli-spacer-row {
        margin-top:3em;
        margin-bottom: 5px;
        border-bottom: 0.5px solid gray; 
    }
    
    .veecli-input-bottom-border {
        height: 3em;
        border-bottom: 0.5px solid gray; 
    }
    </style>