<template>
<div class="panel">

    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">

            <div class="level-left has-text-centered" @click="showFuelPriceCalculator=!showFuelPriceCalculator">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fas fa-plus-square has-text-primary" v-if="!showFuelPriceCalculator && !isPrinting"></i>
                <i class="fas fa-minus-square has-text-danger" v-if="showFuelPriceCalculator && !isPrinting"></i>
            </div>
            <div class="column is-size-6 is-family-sans-serif has-text-centered"> INVOICE FUEL PRICE CALCULATOR </div> 
        </div>
    </div>
    <div class="panel-block">
        <div class="control columns custom-boxes-container has-text-weight-bold" 
             v-if="showFuelPriceCalculator">
            <article class="column is-4 box">
                <!-- <div class="columns ">
                    <div class="column is-6">
                        Invoice Number: 
                    </div>
                    <div class="column has-text-right">
                        <VeeInput 
                            type="text" 
                            v-model="fuelPrice.invoiceNumber"
                            name="invoiceNumber"
                            :value="fuelPrice.invoiceNumber"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div> -->
                <div class="columns">
                    <div class="column is-6">
                        Invoice Amount: 
                    </div>
                    <div class="column has-text-right">
                        <VeeInput 
                            type="currency" 
                            v-model="fuelPrice.invoiceAmount"
                            name="invoiceAmount"
                            :value="fuelPrice.invoiceAmount"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div>
                
                <div class="columns">
                    <div class="column is-6">
                        Required Profit/Gal: 
                    </div>
                    <div class="column has-text-right">
                        <VeeInput 
                            type="currency" 
                            v-model="fuelPrice.profitPerGal"
                            name="profitPerGal"
                            :value="fuelPrice.profitPerGal"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-6">
                        Sales Tax %: 
                    </div>
                    <div class="column has-text-right">
                        {{ fuelPrice.salesTaxPercent }}%
                        <!-- <VeeInput 
                            type="number" 
                            v-model="fuelPrice.salesTaxPercent"
                            name="salesTaxPercent"
                            postfix="%"
                            :value="fuelPrice.salesTaxPercent"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            /> -->
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        Prepaid Sales Tax/Gal: 
                    </div>
                    <div class="column has-text-right">
                        {{ fuelPrice.prepaidSalesTax | formatCurrency }}
                        <!-- <VeeInput 
                            type="currency" 
                            v-model="fuelPrice.prepaidSalesTax"
                            name="prepaidSalesTax"
                            :value="fuelPrice.prepaidSalesTax"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            /> -->
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        Credit Card Fees %: 
                    </div>
                    <div class="column has-text-right">
                        {{  fuelPrice.creditCardFees }}%
                        <!-- <VeeInput 
                            type="number" 
                            v-model="fuelPrice.creditCardFees"
                            name="creditCardFees"
                            postfix="%"
                            :value="fuelPrice.creditCardFees"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            /> -->
                    </div>
                </div>
                <div class="columns" v-if="getTotalGasolineTax()">
                    <div class="column is-9">
                        Total Other Gasoline Taxes: 
                    </div>
                    <div class="column has-text-right is-3">
                        {{ getTotalGasolineTax() | formatCurrency }}
                    </div>
                </div>

                <div class="columns" v-if="getTotalDieselTax()">
                    <div class="column is-9">
                        Total Other Diesel Taxes: 
                    </div>
                    <div class="column has-text-right is-3">
                        {{ getTotalDieselTax() | formatCurrency }}
                    </div>
                </div>

                <div class="columns veecli-table">
                    <div class="column is-4">
                        Freight: 
                    </div>
                    <div class="column has-text-right">
                        <span>
                            {{ fuelPrice.totalFreight / fuelPrice.totalInvoicedVolume | formatCurrency}}
                        </span>
                    </div>
                </div> 
            </article>
            <article class="column box">
                <div class="columns">
                    <div class="column is-4">
                        Fuel Product: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        {{ fuel.productName }}
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-4">
                        Taxable: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <b-switch 
                            v-model="fuel.taxable"
                            :true-value="trueValue"
                            :false-value="falseValue"
                            @input="reCalculate">
                        </b-switch>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-4">
                        Invoiced Volume: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <VeeInput 
                            type="number" 
                            v-model="fuel.invoicedVolume"
                            name="invoicedVolume"
                            postfix=" gal."
                            :value="fuel.invoicedVolume"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-4">
                        Invoiced Base Fuel Cost/Gal: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <VeeInput 
                            v-show="fuel.invoicedVolume > 0"
                            type="currency" 
                            v-model="fuel.invoicedPrice"
                            name="invoicedPrice"
                            posfix=" gal."
                            :value="fuel.invoicedPrice"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div> 

                <div class="columns veecli-table">
                    <div class="column is-4">
                        Before Sales Tax: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0">
                            {{ fuel.taxableSalesPrice  | formatCurrency}}  
                        </span>  
                    </div>
                </div> 

                <div class="columns veecli-table">
                    <div class="column is-4">
                        Sales Tax: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0">
                            {{ fuel.salesTax | formatCurrency}} 
                        </span>
                        
                    </div>
                </div> 

                <!-- <div class="columns veecli-table">
                    <div class="column is-4">
                        Freight: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0 && fuel.productName != 'Diesel'">
                            {{ fuel.freightAndTaxes - getTotalGasolineTax() | formatCurrency}}  g
                        </span>
                        <span v-show="fuel.invoicedVolume > 0 && fuel.productName == 'Diesel'">
                            {{ fuel.freightAndTaxes - getTotalDieselTax() | formatCurrency}}  d
                        </span>
                    </div>
                </div>  -->

                <div class="columns veecli-table  has-background-white-ter">
                    <div class="column is-4">
                        Cash Price: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0">
                            {{ fuel.cashPrice | formatCurrency}} 
                        </span>
                        
                    </div>
                </div> 

                <div class="columns veecli-table">
                    <div class="column is-4">
                        Credit Card Price: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0">
                            {{ fuel.creditPrice | formatCurrency}} 
                        </span>
                        
                    </div>
                </div> 


                <div class="columns veecli-table">
                    <div class="column is-4">
                        Unpaid Sales Tax: 
                    </div>
                    <div class="column has-text-right"  v-bind:key="fuel.productName" v-for="fuel in fuelPrice.fuelProducts">
                        <span v-show="fuel.invoicedVolume > 0">
                            {{ fuel.unpaidSalesTax | formatCurrency}} 
                        </span>
                        
                    </div>
                </div>
                <div class="box">
                    <div class="columns has-background-white-ter">
                        <div class="column is-12 has-text-centered">
                            <div class="is-size-6">
                                SUMMARY
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <div class="columns veecli-table">
                                <div class="column is-7">
                                    Total Invoice Volume: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalInvoicedVolume }} gal.
                                </div>
                            </div>

                            <div class="columns veecli-table">
                                <div class="column is-6">
                                    Total Sales Tax: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalSalesTax | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns veecli-table">
                                <div class="column is-7">
                                    Total Prepaid Sales Tax: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalPrepaidTax | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns veecli-table">
                                <div class="column is-6">
                                    Total Unpaid Sales Tax: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalSalesTax - fuelPrice.totalPrepaidTax | formatCurrency }}
                                </div>
                            </div>
                        </div>
                        
                        <div class="column is-6">

                            <div class="columns veecli-table">
                                <div class="column is-7">
                                    Total Fuel Sales: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalSalePrice | formatCurrency }}
                                </div>
                            </div>

                            <!-- <div class="columns veecli-table">
                                <div class="column is-7">
                                    Total Fuel Base Price: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalFuelBasePrice | formatCurrency }}
                                </div>
                            </div> -->

                            <div class="columns veecli-table">
                                <div class="column is-6">
                                    Total Profit: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalProfit | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns veecli-table">
                                <div class="column is-6">
                                    Profit Percentage: 
                                </div>
                                <div class="column has-text-right">
                                    {{ parseFloat(fuelPrice.totalProfit / fuelPrice.totalSalePrice * 100).toFixed(2) }}%
                                </div>
                            </div>

                            <!-- <div class="columns veecli-table">
                                <div class="column is-6">
                                    Total County Tax: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalCountyTax | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns veecli-table">
                                <div class="column is-6">
                                    Total City Tax: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalCityTax | formatCurrency }}
                                </div>
                            </div> -->

                            <!-- <div class="columns veecli-table">
                                <div class="column is-7">
                                    Total Freight & Other Taxes: 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalFreight + fuelPrice.totalOtherTaxes | formatCurrency}}

                                </div>
                            </div> -->

                            <!-- <div class="columns veecli-table">
                                <div class="column is-6">
                                    Profit (after all taxes): 
                                </div>
                                <div class="column has-text-right">
                                    {{ fuelPrice.totalNetProfit  | formatCurrency }}
                                </div>
                            </div> -->
                        </div>
                        
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <div class="is-size-7">
                                ** Cash Price used for calculating totals
                            </div>
                        </div>
                    </div>
                </div>

                
            </article>
        </div>
    </div>
</div>

</template>

<script>


import VeeInput from './VeeInput.vue'
import UserStore from "../../store/UserStore"
import axios from 'axios'

export default {

    data() {
        return {
            user: this.getUser(),
            showFuelPriceCalculator: false,
            isPrinting: false,
            trueValue: true,
            falseValue: false,
            storeSettingsOptions: UserStore.getters.getStoreSettingsOptions,
            settingsValues: UserStore.getters.getStoreSettingsValues,
            storeState: UserStore.getters.getStoreState,
            fuelPrice: {
                "invoiceNumber": "",
                "invoiceAmount": 0,
                "salesTaxPercent": 0,
                "prepaidSalesTax": 0,
                "countyTaxPerGal": 0,
                "cityTaxPerGal": 0,
                "creditCardFees": 0,
                "profitPerGal": 0,
                "totalOtherTaxes": 0,
                "totalFreight": 0,
                "totalInvoicedVolume": 0,
                "totalFuelBasePrice": 0,
                "totalPrepaidTax": 0,
                "totalCityTax": 0,
                "totalCountyTax": 0,
                "totalProfit": 0,
                "totalNetProfit": 0,
                "totalSalePrice": 0,
                "totalSalesTax": 0,
                "fuelProducts": [
                    {
                        "productName": "Regular",
                        "taxable": true,
                        "invoicedVolume": 0,
                        "invoicedPrice": 0,
                        "taxableSalesPrice": 0,
                        "salesTax": 0,
                        "countyTaxPerGal": 0,
                        "cityTaxPerGal": 0,
                        "freightAndTaxes": 0,
                        "cashPrice": 0,
                        "creditPrice": 0,
                        "unpaidSalesTax": 0,
                        "totalTaxDue": 0
                    },
                    {
                        "productName": "Premium",
                        "taxable": true,
                        "invoicedVolume": 0,
                        "invoicedPrice": 0,
                        "taxableSalesPrice": 0,
                        "salesTax": 0,
                        "countyTaxPerGal": 0,
                        "cityTaxPerGal": 0,
                        "freightAndTaxes": 0,
                        "cashPrice": 0,
                        "creditPrice": 0,
                        "unpaidSalesTax": 0,
                        "totalTaxDue": 0
                    },
                    {
                        "productName": "Diesel",
                        "taxable": false,
                        "invoicedVolume": 0,
                        "invoicedPrice": 0,
                        "taxableSalesPrice": 0,
                        "salesTax": 0,
                        "countyTaxPerGal": 0,
                        "cityTaxPerGal": 0,
                        "freightAndTaxes": 0,
                        "cashPrice": 0,
                        "creditPrice": 0,
                        "unpaidSalesTax": 0,
                        "totalTaxDue": 0
                    },
                    {
                        "productName": "Other",
                        "taxable": true,
                        "invoicedVolume": 0,
                        "invoicedPrice": 0,
                        "taxableSalesPrice": 0,
                        "salesTax": 0,
                        "countyTaxPerGal": 0,
                        "cityTaxPerGal": 0,
                        "freightAndTaxes": 0,
                        "cashPrice": 0,
                        "creditPrice": 0,
                        "unpaidSalesTax": 0,
                        "totalTaxDue": 0
                    }
                ]
            },
            countryCurrencyCode: this.getCountryCurrencyCode()
        }
    },

    components: {
        VeeInput
    },


    methods: {

        fetchSettings() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/settings'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.storeSettings = response.data.data

                        this.setSettingsValues()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        setSettingsValues() {

            this.fuelPrice.creditCardFees = (this.settingsValues.fpcalcCreditCardPerc.value ? this.getNumber(this.settingsValues.fpcalcCreditCardPerc.value) : 0)
            this.fuelPrice.salesTaxPercent = (this.settingsValues.fpcalcSalesTaxPerc.value ? this.getNumber(this.settingsValues.fpcalcSalesTaxPerc.value) : 0)
            this.fuelPrice.prepaidSalesTax = (this.settingsValues.fpcalcSalesTaxPrepaid.value ? this.getNumber(this.settingsValues.fpcalcSalesTaxPrepaid.value) : 0)
            this.fuelPrice.cityTaxPerGal = (this.settingsValues.fpcalcCityFuelTax.value ? this.getNumber(this.settingsValues.fpcalcCityFuelTax.value) : 0)
            this.fuelPrice.countyTaxPerGal = (this.settingsValues.fpcalcCountyFuelTax.value ? this.getNumber(this.settingsValues.fpcalcCountyFuelTax.value) : 0)
            
        },

        getCommonTax() {
            return this.getNumber(this.settingsValues.fpcalcStateUSTTax.value) +
                this.getNumber(this.settingsValues.fpcalcStateENVTax.value) +
                this.getNumber(this.settingsValues.fpcalcFedLUSTTax.value) +
                this.getNumber(this.settingsValues.fpcalcFedOilSpillTax.value) +
                this.getNumber(this.settingsValues.fpcalcCityFuelTax.value) +
                this.getNumber(this.settingsValues.fpcalcCountyFuelTax.value)
        },

        getTotalGasolineTax() {
            return this.getNumber(this.getCommonTax()) + 
                this.getNumber(this.settingsValues.fpcalcStateGasolinelUseTax.value) +
                this.getNumber(this.settingsValues.fpcalcStateGasolineTax.value) +
                this.getNumber(this.settingsValues.fpcalcFedGasolineHAZSSF.value) +
                this.getNumber(this.settingsValues.fpcalcFedGasolineExciseTax.value) 
        },

        getTotalDieselTax() {
            return this.getNumber(this.getCommonTax()) +
                this.getNumber(this.settingsValues.fpcalcStateDieselUseTax.value) +
                this.getNumber(this.settingsValues.fpcalcStateDieselTax.value) +
                this.getNumber(this.settingsValues.fpcalcFedDieselHAZSSF.value) +
                this.getNumber(this.settingsValues.fpcalcFedDieselExciseTax.value) 
        },
        
        reCalculate() {

            this.fuelPrice.totalFuelBasePrice = 0
            this.fuelPrice.totalInvoicedVolume = 0
            this.fuelPrice.totalPrepaidTax = 0
            this.fuelPrice.totalSalesTax = 0
            this.fuelPrice.totalProfit = 0
            this.fuelPrice.totalNetProfit = 0
            this.fuelPrice.totalOtherTaxes = 0
            this.fuelPrice.totalFreight = 0
            this.fuelPrice.totalSalePrice = 0

            this.fuelPrice.fuelProducts.forEach((fuel) => {
                this.fuelPrice.totalInvoicedVolume += fuel.invoicedVolume
                this.fuelPrice.totalFuelBasePrice += fuel.invoicedVolume * fuel.invoicedPrice
                if (fuel.taxable) {
                    this.fuelPrice.totalPrepaidTax += fuel.invoicedVolume * this.fuelPrice.prepaidSalesTax
                }
            })

            this.fuelPrice.totalFreight = this.fuelPrice.totalInvoicedVolume * this.settingsValues.fpcalcFreightPerGal.value

            this.fuelPrice.totalOtherTaxes = this.fuelPrice.invoiceAmount - 
                this.fuelPrice.totalFuelBasePrice - 
                this.fuelPrice.totalPrepaidTax - 
                this.fuelPrice.totalFreight

            this.fuelPrice.fuelProducts.forEach((fuel) => {

                fuel.freightAndTaxes = parseFloat((this.fuelPrice.totalFreightAndTaxes / this.fuelPrice.totalInvoicedVolume).toFixed(2))
                if (!fuel.invoicedVolume || !fuel.invoicedPrice) {
                    return
                }

                if (fuel.productName == "Diesel") {                    
                    fuel.taxableSalesPrice = fuel.invoicedPrice + this.getTotalDieselTax() + this.fuelPrice.profitPerGal + this.getNumber(this.settingsValues.fpcalcFreightPerGal.value)
                } else {
                    fuel.taxableSalesPrice = fuel.invoicedPrice +  this.getTotalGasolineTax() + this.fuelPrice.profitPerGal + this.getNumber(this.settingsValues.fpcalcFreightPerGal.value)
                }
                if (fuel.taxable) {
                    fuel.salesTax = parseFloat((fuel.taxableSalesPrice * this.fuelPrice.salesTaxPercent / 100).toFixed(2))
                    fuel.unpaidSalesTax = fuel.salesTax - this.fuelPrice.prepaidSalesTax
                } else {
                    fuel.salesTax = 0
                    fuel.unpaidSalesTax = 0
                }
                
                fuel.cashPrice = this.getNumber(parseFloat(fuel.taxableSalesPrice + fuel.salesTax).toFixed(2))
                var creditCardFees = fuel.cashPrice * this.getNumber(parseFloat((this.fuelPrice.creditCardFees / 100).toFixed(2)))
                fuel.creditPrice = fuel.cashPrice + creditCardFees
                
                this.fuelPrice.totalSalePrice += (fuel.cashPrice * fuel.invoicedVolume)
                this.fuelPrice.totalSalesTax += (fuel.salesTax * fuel.invoicedVolume)

            })
            this.fuelPrice.totalCityTax = this.fuelPrice.totalInvoicedVolume 
                                    * this.fuelPrice.cityTaxPerGal

            this.fuelPrice.totalCountyTax = this.fuelPrice.totalInvoicedVolume 
                                    * this.fuelPrice.countyTaxPerGal

            this.fuelPrice.totalProfit = this.fuelPrice.totalInvoicedVolume 
                            * this.fuelPrice.profitPerGal

            this.fuelPrice.totalNetProfit = this.fuelPrice.totalProfit
                            - this.fuelPrice.totalCityTax
                            - this.fuelPrice.totalCountyTax
                            - this.fuelPrice.totalSalesTax
                            + this.fuelPrice.totalPrepaidTax
            
        },

    },

    mounted() {
        this.fetchSettings()
    }

}
</script>


<style>
.box {
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.custom-boxes-container .box:last-child { margin-bottom: 1.5rem;}
</style>