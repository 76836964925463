<template>
	<div class="container">
        <calculator-merchandise-price></calculator-merchandise-price>

		<calculator-break-even-fuel-price></calculator-break-even-fuel-price>

        <calculator-fuel-price></calculator-fuel-price>
	</div>
</template>

<script>

import CalculatorMerchandisePrice from "../../../components/app/CalculatorMerchandisePrice"
import CalculatorBreakEvenFuelPrice from "../../../components/app/CalculatorBreakEvenFuelPrice"
import CalculatorFuelPrice from "../../../components/app/CalculatorFuelPrice"

export default {
	components: {
		CalculatorMerchandisePrice,
		CalculatorBreakEvenFuelPrice,
		CalculatorFuelPrice
	}
}
</script>